const calendarActionTypes = {
  GET_CALENDAR_STATUS_DATA: "GET_CALENDAR_STATUS_DATA",
  GET_CALENDAR_VALUES_DATA: "GET_CALENDAR_VALUES_DATA",
  GET_CALENDAR_DAILY_VALUES_DATA: "GET_CALENDAR_DAILY_VALUES_DATA",
  SET_CALENDAR_CHOSEN_YEAR: "SET_CALENDAR_CHOSEN_YEAR",
  SET_CALENDAR_CHOSEN_DAY: "SET_CALENDAR_CHOSEN_DAY",
  SET_CALENDAR_CHOSEN_CITY: "SET_CALENDAR_CHOSEN_CITY"
};

export default calendarActionTypes;
