import React from "react";

const VeryBadEmot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <path
        fill="#CC571A"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M22.4 0c5.905 0 11.454 2.3 15.634 6.475 8.614 8.618 8.614 22.638-.005 31.256a21.95 21.95 0 01-15.625 6.472c-5.906 0-11.455-2.299-15.626-6.472A21.954 21.954 0 01.3 22.1a21.954 21.954 0 016.474-15.63C10.945 2.298 16.495 0 22.4 0zm9.72 10.007a.93.93 0 00-.717.337 11.704 11.704 0 01-9.01 4.2c-3.468-.001-6.749-1.532-9.003-4.202a.938.938 0 00-1.434 1.21 13.633 13.633 0 0010.437 4.868c3.65 0 7.077-1.421 9.658-4.001.283-.28.54-.572.792-.873a.939.939 0 00-.722-1.54zM15.99 27.478a2.816 2.816 0 100-5.628 2.816 2.816 0 000 5.628zm12.825 0a2.816 2.816 0 000-5.63 2.816 2.816 0 000 5.63z"
        transform="translate(-358 -114) matrix(1 0 0 -1 357.995 159)"
      ></path>
    </svg>
  );
};

export default VeryBadEmot;
