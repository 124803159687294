const chartsActionTypes = {
  SET_CHART_CHOSEN_CITY: "SET_CHART_CHOSEN_CITY",
  GET_CHART_FIRST_MONTH: "GET_CHART_FIRST_MONTH",
  SET_CHART_CHOSEN_INDICATOR: "SET_CHART_CHOSEN_INDICATOR",
  SET_CHART_CHOSEN_MONTH: "SET_CHART_CHOSEN_MONTH",
  GET_CHART_HOURLY_AVERAGE_FOR_MONTH_DATA:
    "GET_CHART_HOURLY_AVERAGE_FOR_MONTH_DATA"
};

export default chartsActionTypes;
