const PollutionScaleContent = {
  colorBox: [
    "good-first",
    "good-second",
    "unhealthy-first",
    "unhealthy-second",
    "bad-first",
    "bad-second"
  ],
  description: ["Dobra", "Niezdrowa", "Bardzo zła"]
};

export default PollutionScaleContent;
