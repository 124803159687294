import React from "react";

const NeutralEmot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <path
        fill="#A1A3A5"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M22.4 0c5.904 0 11.454 2.3 15.633 6.475 8.614 8.618 8.614 22.638-.004 31.256a21.952 21.952 0 01-15.626 6.472A21.948 21.948 0 016.777 37.73 21.954 21.954 0 01.3 22.1a21.954 21.954 0 016.474-15.63C10.945 2.298 16.494 0 22.4 0zm-6.411 27.479a2.816 2.816 0 000-5.63 2.816 2.816 0 000 5.63zm12.825 0a2.816 2.816 0 10-2.815-2.816 2.817 2.817 0 002.815 2.816zM14.424 9.336a.937.937 0 00-.29 1.831l18.14 5.944a.944.944 0 001.185-.602.942.942 0 00-.602-1.184l-18.14-5.943a.969.969 0 00-.294-.046z"
        transform="translate(-64 -115) matrix(1 0 0 -1 63.7 159.267)"
      ></path>
    </svg>
  );
};

export default NeutralEmot;
