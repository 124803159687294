export const newsActionTypes = {
  GET_ARTICLES_SUCCESS: "GET_ARTICLES_SUCCESS",
  GET_ARTICLES_PENDING: "GET_ARTICLES_PENDING",
  GET_ARTICLES_FAILURE: "GET_ARTICLES_FAILURE",
  GET_ARTICLE_SUCCESS: "GET_ARTICLE_SUCCESS",
  GET_ARTICLE_PENDING: "GET_ARTICLE_PENDING",
  GET_ARTICLE_FAILURE: "GET_ARTICLE_FAILURE",
  RESET_ARTICLE: "RESET_ARTICLE",
  SET_ARTICLES_PAGE: "SET_ARTICLES_PAGE"
};
