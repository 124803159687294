import React from "react";

const DramaticEmot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="51"
      viewBox="0 0 45 51"
    >
      <g fill="#7D0D0F" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          d="M16 33.8a2.816 2.816 0 100-5.628 2.816 2.816 0 000 5.628m12.825 0a2.816 2.816 0 10-.001-5.632 2.816 2.816 0 00.001 5.632m-6.662-9.35c6.336 0 11.472-5.136 11.472-11.47 0-1.335-.24-2.61-.659-3.802l.147-.093c1.781.99 3.439 2.23 4.922 3.71 8.614 8.62 8.614 22.639-.005 31.256a21.95 21.95 0 01-15.625 6.472c-5.906 0-11.456-2.298-15.627-6.472A21.956 21.956 0 01.31 28.422a21.956 21.956 0 016.473-15.63 22.201 22.201 0 014.528-3.488 11.357 11.357 0 00-.621 3.676c0 6.334 5.136 11.47 11.472 11.47"
          transform="translate(-417 -114) matrix(1 0 0 -1 417.183 165.321)"
        ></path>
        <path
          d="M32.324 9.294l-.123.077c.389 1.105.61 2.287.61 3.523 0 5.87-4.758 10.63-10.63 10.63-5.869 0-10.628-4.76-10.628-10.63 0-1.194.21-2.335.575-3.404l.006-.004c.065-.192.126-.385.202-.57l-1.895-.977-.895 1.735L5.682 7.68 9.372.524l3.864 1.99-.97 1.884 2.25 1.16a10.6 10.6 0 0115.679.38l2.177-1.367-1.086-1.799L35.011.528l4.157 6.896-3.723 2.245-1.016-1.685-2.092 1.317a20.24 20.24 0 00-.013-.007zm-9.242-2.553c.239-.233.389-.556.389-.918a1.289 1.289 0 10-2.578 0c0 .37.159.7.407.934l.015-.001a1.278 1.278 0 001.75-.015h.017zm-.9 10.34a4.187 4.187 0 100-8.375 4.187 4.187 0 000 8.375zm-7.086-5.475a1.287 1.287 0 100 2.577 1.288 1.288 0 000-2.577zm7.086 9.663a1.288 1.288 0 10.002-2.576 1.288 1.288 0 00-.002 2.576zm7.086-7.086a1.288 1.288 0 100-2.577 1.288 1.288 0 000 2.577z"
          transform="translate(-417 -114) matrix(1 0 0 -1 417.183 165.321)"
        ></path>
      </g>
    </svg>
  );
};

export default DramaticEmot;
