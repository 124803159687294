import React from "react";
import { PropTypes } from "prop-types";

import "./TownImage.scss";

const TownImage = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 619.26 348.72"
      width="619.26"
      height="348.72"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="334.74"
          y1="348.35"
          x2="334.74"
          y2="2.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" stopOpacity="0.5" />
          <stop offset={1} stopColor={color} />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="479.46"
          y1="292.3"
          x2="479.46"
          y2="155.13"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="162.48"
          y1="332.24"
          x2="162.48"
          y2="194.44"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="250.3"
          y1="189.83"
          x2="250.3"
          y2="106.26"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <title>TownImage</title>
      <g id="Layer_4" data-name="Layer 4">
        <path
          className="cls-1"
          d="M323.48,339c18.93,6.63,200.33,22.62,230.76-13.23s-7.13-77.09,15.42-90.55S610,202.88,595.47,164.5,528.86,136.39,498,119.05,498,77.58,467,50.14s-52.22-9.64-81.44-18.28S368.06-1.91,327.5,3s-18.71,57.83-34.21,62.32-29.91-12.38-67,8-10.49,66.13-40,77.38-42.66-15.92-73-.58-24,37.11-12,56.82-20.42,2.72-29.91,31.61S77,303.92,137.67,310s122.53-38.14,153.84-15S293.29,328.44,323.48,339Z"
          transform="translate(-0.63 -2.38)"
        />
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <path
          className="cls-2"
          d="M256.72,136.36c-1.81-11.7-11.71-43.76-16.87-43.79s-15.45,31.91-17.4,43.59,3.65,18.23,17,18.31S258.53,148.06,256.72,136.36Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-3"
          d="M256.26,133.77c-1.95,5.8-7.51,8.9-16.78,8.84s-14.62-3.13-16.58-8.78c-.18.84-.33,1.63-.44,2.33-2,11.68,3.64,18.23,17,18.31s19-6.41,17.24-18.11C256.6,135.59,256.44,134.71,256.26,133.77Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-3"
          d="M285,124.5c-1.81-11.7-11.71-43.76-16.88-43.79s-15.44,31.91-17.39,43.59,3.65,18.23,17,18.31S286.76,136.2,285,124.5Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-4"
          d="M284.82,123.72c-1.56,6.57-7.23,10.11-17.11,10.05s-15.37-3.58-16.93-10c0,.18-.07.37-.1.54-2,11.68,3.65,18.23,17,18.31S286.76,136.2,285,124.5C284.91,124.26,284.86,124,284.82,123.72Z"
          transform="translate(-0.63 -2.38)"
        />
        <polygon
          className="cls-5"
          points="379.49 85.36 360.92 115.51 274.24 115.51 292.1 85.36 379.49 85.36"
        />
        <rect
          className="cls-6"
          x="274.24"
          y="115.51"
          width="87.39"
          height="46.47"
        />
        <polygon
          className="cls-7"
          points="379.49 85.36 360.92 115.51 360.92 161.98 398.07 161.98 398.07 115.51 379.49 85.36"
        />
        <polygon
          className="cls-8"
          points="317.94 91 292.66 115.51 292.66 161.98 344.21 161.98 344.21 115.51 317.94 91"
        />
        <rect
          className="cls-9"
          x="309.64"
          y="134.43"
          width="17.23"
          height="27.54"
        />
        <rect
          className="cls-5"
          x="311.63"
          y="137.03"
          width="13.25"
          height="24.34"
        />
        <rect
          className="cls-10"
          x="312.99"
          y="112.89"
          width="10.9"
          height="9.03"
        />
        <rect
          className="cls-11"
          x="313.99"
          y="114.89"
          width="6.71"
          height="5.55"
        />
        <rect
          className="cls-10"
          x="374.04"
          y="112.11"
          width="10.9"
          height="9.03"
        />
        <rect
          className="cls-11"
          x="375.04"
          y="113.89"
          width="6.71"
          height="5.55"
        />
        <rect
          className="cls-10"
          x="374.04"
          y="138.74"
          width="10.9"
          height="9.03"
        />
        <rect
          className="cls-11"
          x="375.04"
          y="140.48"
          width="6.71"
          height="5.55"
        />
        <line
          className="cls-12"
          x1="258.42"
          y1="121.92"
          x2="267.16"
          y2="131.84"
        />
        <polyline
          className="cls-12"
          points="272.1 106.49 267.16 117.4 267.16 161.98"
        />
        <line
          className="cls-12"
          x1="267.16"
          y1="106.49"
          x2="267.16"
          y2="117.4"
        />
        <path
          className="cls-2"
          d="M241.84,163.36H410.53c41.82,0,24.93,42.89,61.38,43.62s50.77,21.11,56.51,42.52,23.89,23.85,37.27,43.71,6.69,45.13,6.69,45.13L216.87,301.89Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-13"
          d="M56.74,349.6H600.31s4-30.81-41.12-36.7c-27-3.52-23.27-32.48-60.17-42.29s-383.14,9.81-383.14,9.81S61.75,286.06,56.74,349.6Z"
          transform="translate(-0.63 -2.38)"
        />
        <rect
          className="cls-8"
          x="243.76"
          y="213.39"
          width="97.02"
          height="133.82"
        />
        <polygon
          className="cls-7"
          points="360.28 195.87 353.83 178.34 347.38 195.87 340.93 213.39 340.93 347.22 366.73 347.22 366.73 213.39 360.28 195.87"
        />
        <polygon
          className="cls-5"
          points="353.7 178.34 340.93 213.39 243.76 213.39 256.82 178.34 353.7 178.34"
        />
        <rect
          className="cls-9"
          x="274.55"
          y="297.68"
          width="32.66"
          height="49.54"
        />
        <rect
          className="cls-5"
          x="278.13"
          y="301.07"
          width="25.49"
          height="46.14"
        />
        <rect
          className="cls-14"
          x="252.38"
          y="244.9"
          width="15.45"
          height="19.63"
        />
        <rect
          className="cls-9"
          x="254.43"
          y="248.47"
          width="5.36"
          height="12.49"
        />
        <rect
          className="cls-14"
          x="252.38"
          y="299.51"
          width="15.45"
          height="19.63"
        />
        <rect
          className="cls-14"
          x="315.13"
          y="299.51"
          width="15.45"
          height="19.63"
        />
        <rect
          className="cls-14"
          x="283.76"
          y="244.9"
          width="15.45"
          height="19.63"
        />
        <rect
          className="cls-14"
          x="315.13"
          y="244.9"
          width="15.45"
          height="19.63"
        />
        <rect
          className="cls-15"
          x="349.92"
          y="299.51"
          width="15.45"
          height="19.63"
        />
        <rect
          className="cls-15"
          x="349.92"
          y="244.9"
          width="15.45"
          height="19.63"
        />
        <polygon
          className="cls-7"
          points="488.9 213.39 462.95 252.31 462.95 347.22 514.84 347.22 514.84 252.31 488.9 213.39"
        />
        <polygon
          className="cls-5"
          points="340.78 252.31 366.73 213.39 488.9 213.39 463 252.31 340.78 252.31"
        />
        <polygon
          className="cls-6"
          points="422.15 252.31 401.32 231.64 377.96 252.31 340.64 252.31 340.64 347.22 462.86 347.22 462.86 252.31 422.15 252.31"
        />
        <polygon
          className="cls-14"
          points="401.23 231.64 421.27 252.31 453.84 252.31 433.45 232.06 401.23 231.64"
        />
        <rect
          className="cls-9"
          x="386.75"
          y="285.59"
          width={30}
          height="61.63"
        />
        <rect
          className="cls-5"
          x="389.66"
          y="289.43"
          width="24.18"
          height="57.79"
        />
        <rect
          className="cls-16"
          x="354.11"
          y="286.64"
          width="20.25"
          height="25.73"
        />
        <rect
          className="cls-9"
          x="356.11"
          y="290.74"
          width="11.26"
          height="19.63"
        />
        <rect
          className="cls-16"
          x="429.72"
          y="286.64"
          width="20.25"
          height="25.73"
        />
        <rect
          className="cls-9"
          x="432.77"
          y="290.74"
          width="11.26"
          height="19.63"
        />
        <rect
          className="cls-11"
          x="481.94"
          y="258.41"
          width="15.45"
          height="19.63"
        />
        <rect
          className="cls-11"
          x="481.94"
          y="292.74"
          width="15.45"
          height="19.63"
        />
        <rect
          className="cls-10"
          x="484.94"
          y="294.6"
          width="7.72"
          height="11.15"
        />
        <rect
          className="cls-10"
          x="484.94"
          y="262.65"
          width="7.72"
          height="11.15"
        />
        <circle className="cls-16" cx="400.99" cy="254.71" r="9.81" />
        <circle className="cls-9" cx="400.99" cy="254.71" r="7.59" />
        <rect
          className="cls-10"
          x="225.64"
          y="139.23"
          width="18.12"
          height="207.99"
        />
        <rect
          className="cls-9"
          x="169.49"
          y="139.23"
          width="56.15"
          height="207.99"
        />
        <rect
          className="cls-10"
          x="151.37"
          y="139.23"
          width="18.12"
          height="207.99"
        />
        <rect
          className="cls-11"
          x="151.37"
          y="139.23"
          width="7.46"
          height="207.99"
        />
        <rect
          className="cls-11"
          x="225.64"
          y="139.23"
          width="7.46"
          height="207.99"
        />
        <polygon
          className="cls-9"
          points="197.57 29.48 225.64 139.23 197.45 139.23 169.26 139.23 197.57 29.48"
        />
        <polygon
          className="cls-16"
          points="198.06 215.07 179.24 215.07 160.43 215.07 160.43 274.73 179.24 274.73 198.06 274.73 216.87 274.73 216.87 215.07 198.06 215.07"
        />
        <polygon
          className="cls-17"
          points="206.86 194.13 206.83 194.13 188.02 194.13 183.47 194.13 169.2 194.13 160.43 215.07 174.67 215.07 179.24 215.07 198.06 215.07 216.87 215.07 225.64 194.13 206.86 194.13"
        />
        <polygon
          className="cls-5"
          points="243.76 139.23 197.57 29.48 225.64 139.23 243.76 139.23"
        />
        <polygon
          className="cls-10"
          points="256.82 178.34 243.76 178.34 243.76 213.39 256.82 178.34"
        />
        <circle className="cls-6" cx="197.28" cy="153.32" r="7.66" />
        <circle className="cls-18" cx="197.28" cy="153.32" r="6.48" />
        <polygon
          className="cls-5"
          points="216.87 274.73 225.64 274.73 225.64 194.13 216.87 215.07 216.87 274.73"
        />
        <line
          className="cls-19"
          x1="160.43"
          y1="244.9"
          x2="216.24"
          y2="244.9"
        />
        <line
          className="cls-19"
          x1="169.84"
          y1="167.41"
          x2="225.64"
          y2="167.41"
        />
        <line
          className="cls-19"
          x1="169.2"
          y1="309.32"
          x2="225.64"
          y2="309.32"
        />
        <polyline
          className="cls-19"
          points="179.24 274.73 179.24 215.07 188.02 194.13 188.02 139.23 197.57 29.48 206.83 139.23 206.83 194.13 198.06 215.07 198.06 274.73"
        />
        <line
          className="cls-19"
          x1="179.24"
          y1="98.67"
          x2="226.44"
          y2="98.67"
        />
        <rect
          className="cls-6"
          x="96.6"
          y="234.43"
          width="54.77"
          height="112.79"
        />
        <polygon
          className="cls-5"
          points="114.81 208.23 96.6 234.43 151.37 234.43 151.37 208.23 114.81 208.23"
        />
        <rect
          className="cls-9"
          x="106.11"
          y="252.31"
          width="12.12"
          height="15.91"
        />
        <rect
          className="cls-9"
          x="127.55"
          y="252.31"
          width="12.12"
          height="15.91"
        />
        <rect
          className="cls-9"
          x="106.11"
          y="294.6"
          width="12.12"
          height="15.91"
        />
        <rect
          className="cls-9"
          x="127.55"
          y="294.6"
          width="12.12"
          height="15.91"
        />
        <rect
          className="cls-16"
          x="129.18"
          y="295.79"
          width="3.43"
          height="13.53"
        />
        <rect
          className="cls-16"
          x="129.18"
          y="253.51"
          width="3.43"
          height="13.53"
        />
        <rect
          className="cls-16"
          x="107.74"
          y="295.79"
          width="3.43"
          height="13.53"
        />
        <path
          className="cls-2"
          d="M592.42,297.61c-3.08-19.92-19.94-74.51-28.73-74.57s-26.3,54.34-29.62,74.23,6.21,31,29,31.17S595.49,317.53,592.42,297.61Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-3"
          d="M592.21,296.38c-2.64,11.19-12.3,17.22-29.15,17.12-16.63-.1-26.18-6.08-28.85-17,0,.27-.09.54-.14.81-3.31,19.88,6.21,31,29,31.17s32.43-10.91,29.36-30.83C592.35,297.22,592.29,296.8,592.21,296.38Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-13"
          d="M554.84,249.35a7,7,0,0,1-1.54-5.48,26.61,26.61,0,0,0,.33-4.22,27.42,27.42,0,0,0-6.67-18,7.32,7.32,0,0,1-1.28-7.42,21,21,0,1,0-39.21.11,7.16,7.16,0,0,1-1.32,7.3,27.45,27.45,0,0,0-6.67,18,27.85,27.85,0,0,0,.32,4.22,7.05,7.05,0,0,1-1.56,5.52,37.14,37.14,0,1,0,57.6,0Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-20"
          d="M524.61,289.48a37.37,37.37,0,0,1-34.27-26.91,37.15,37.15,0,1,0,72.86,10.25,36.75,36.75,0,0,0-1.44-10.23A37.16,37.16,0,0,1,524.61,289.48Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-2"
          d="M96.61,337.4C96,328.47,91.52,321.52,86,321.52c-5.87,0-10.63,8-10.63,17.83a26.68,26.68,0,0,0,1.93,10.25H105c2.86-4.33,3.43-8.84,1-11.33C104,336.2,100.44,336,96.61,337.4Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-2"
          d="M506.48,335.27c1.14-8.68,7.12-15.3,14.33-15.3,6.92,0,12.71,6.1,14.17,14.27a9,9,0,0,1,4.44-1.18c5.36,0,9.72,4.77,9.72,10.66a11.31,11.31,0,0,1-1.62,5.88H500.65c-3-3.54-3.54-8.15-.87-11.3A9.19,9.19,0,0,1,506.48,335.27Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-3"
          d="M548.58,339.37c-.74-6.2-4.63-10.93-9.32-10.93-4.51,0-8.27,4.36-9.22,10.19a5.53,5.53,0,0,0-2.89-.84c-3.49,0-6.32,3.41-6.32,7.62a8.7,8.7,0,0,0,1,4.19h30.49c2-2.53,2.3-5.82.56-8.07A5.78,5.78,0,0,0,548.58,339.37Z"
          transform="translate(-0.63 -2.38)"
        />
        <line
          className="cls-19"
          x1="188.33"
          y1="274.2"
          x2="188.33"
          y2="346.54"
        />
        <line
          className="cls-19"
          x1="206.83"
          y1="274.73"
          x2="206.83"
          y2="347.22"
        />
        <line
          className="cls-19"
          x1="169.26"
          y1="139.23"
          x2="225.64"
          y2="139.23"
        />
        <line
          className="cls-12"
          x1="525.83"
          y1="213.27"
          x2="525.83"
          y2="347.09"
        />
        <path
          className="cls-12"
          d="M509.65,227.39s-3,17.46,16.2,17"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-12"
          d="M549.14,257.1s5.77,27.61-22.68,25.46"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-12"
          d="M515.47,262.65s-.8,9,10.58,8"
          transform="translate(-0.63 -2.38)"
        />
        <line
          className="cls-12"
          x1="562.57"
          y1="299.51"
          x2="562.57"
          y2="347.22"
        />
        <line
          className="cls-12"
          x1="575.49"
          y1="294.6"
          x2="562.57"
          y2="316.91"
        />
        <line className="cls-12" x1="82.32" y1="292.6" x2="82.32" y2="345.22" />
        <path
          className="cls-12"
          d="M59.82,298.06S56.16,319.5,83,319.52"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-12"
          d="M113.13,275.11c0,1.37,4.3,28.94-30.1,31"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-3"
          d="M137.67,243.84a22.5,22.5,0,0,0-13-20.42,4.85,4.85,0,0,1-2.73-4.8c.06-.82.09-1.64.09-2.47a32.9,32.9,0,0,0-65.2-6.28,4.9,4.9,0,0,1-4.87,3.93h-.14A32.9,32.9,0,0,0,30,271.33a5,5,0,0,1,1.28,5.72c-3.08,7.12-2.59,16.14,3.78,24.84a4.93,4.93,0,0,0,1.05,1c14.12,10.25,29.13,5.09,35.38-5.75a5,5,0,0,1,5.77-2.32,32.89,32.89,0,0,0,42.2-26,4.89,4.89,0,0,1,3.23-3.8A22.52,22.52,0,0,0,137.67,243.84Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-4"
          d="M122.67,246.6a4.9,4.9,0,0,0-3.23,3.8,32.89,32.89,0,0,1-42.2,26,5,5,0,0,0-5.77,2.32c-6.25,10.84-21.26,16-35.38,5.76A5.22,5.22,0,0,1,35,283.42c-.66-.9-2.46-2-4.84-3.29-1.76,6.55-.64,14.27,4.84,21.76a4.93,4.93,0,0,0,1.05,1c14.12,10.25,29.13,5.09,35.38-5.75a5,5,0,0,1,5.77-2.32,32.89,32.89,0,0,0,42.2-26,4.89,4.89,0,0,1,3.23-3.8,22.53,22.53,0,0,0,13-30.47A22.59,22.59,0,0,1,122.67,246.6Z"
          transform="translate(-0.63 -2.38)"
        />
        <rect
          className="cls-9"
          x="285.78"
          y="248.47"
          width="5.36"
          height="12.49"
        />
        <rect
          className="cls-9"
          x="317.57"
          y="248.47"
          width="5.36"
          height="12.49"
        />
        <rect
          className="cls-9"
          x="254.43"
          y="303.07"
          width="5.36"
          height="12.49"
        />
        <rect
          className="cls-9"
          x="317.57"
          y="303.07"
          width="5.36"
          height="12.49"
        />
        <path
          className="cls-13"
          d="M80.94,332.94c-1.21-10.09-7.54-17.79-15.18-17.79-7.33,0-13.46,7.1-15,16.59a8.88,8.88,0,0,0-4.69-1.37c-5.69,0-10.3,5.55-10.3,12.4a14.14,14.14,0,0,0,1.71,6.83H87.12c3.22-4.12,3.74-9.48.91-13.14A9.43,9.43,0,0,0,80.94,332.94Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-20"
          d="M82.26,340.19a12.11,12.11,0,0,0-23.72-.67,9,9,0,0,0-3.69-.78c-4.47,0-8.09,3.14-8.09,7a6.36,6.36,0,0,0,1.34,3.86h39c2.54-2.33,3-5.35.72-7.42A8.67,8.67,0,0,0,82.26,340.19Z"
          transform="translate(-0.63 -2.38)"
        />
        <polygon
          className="cls-9"
          points="161.85 236.21 161.85 216.17 174.05 216.17 161.85 236.21"
        />
        <polygon
          className="cls-9"
          points="181.23 236.21 181.23 216.17 193.43 216.17 181.23 236.21"
        />
        <polygon
          className="cls-9"
          points="199.73 236.21 199.73 216.17 211.93 216.17 199.73 236.21"
        />
        <polygon
          className="cls-21"
          points="171.74 189.89 171.74 169.84 183.94 169.84 171.74 189.89"
        />
        <polygon
          className="cls-21"
          points="171.74 162.11 171.74 142.07 183.94 142.07 171.74 162.11"
        />
        <polygon
          className="cls-21"
          points="192.12 189.89 192.12 169.84 204.32 169.84 192.12 189.89"
        />
        <polygon
          className="cls-21"
          points="210.62 189.89 210.62 169.84 222.82 169.84 210.62 189.89"
        />
        <polygon
          className="cls-21"
          points="171.74 298.26 171.74 278.22 183.94 278.22 171.74 298.26"
        />
        <polygon
          className="cls-21"
          points="192.12 298.26 192.12 278.22 204.32 278.22 192.12 298.26"
        />
        <polygon
          className="cls-21"
          points="210.62 298.26 210.62 278.22 222.82 278.22 210.62 298.26"
        />
        <polygon
          className="cls-21"
          points="210.62 162.11 210.62 142.07 222.82 142.07 210.62 162.11"
        />
        <polygon
          className="cls-9"
          points="161.85 256.14 161.85 246.12 169.05 246.12 161.85 256.14"
        />
        <polygon
          className="cls-9"
          points="181.23 256.14 181.23 246.12 188.43 246.12 181.23 256.14"
        />
        <polygon
          className="cls-9"
          points="199.73 256.14 199.73 246.12 206.93 246.12 199.73 256.14"
        />
        <polygon
          className="cls-21"
          points="171.39 321.6 171.39 311.58 178.59 311.58 171.39 321.6"
        />
        <polygon
          className="cls-21"
          points="191.78 321.6 191.78 311.58 198.98 311.58 191.78 321.6"
        />
        <polygon
          className="cls-21"
          points="210.27 321.6 210.27 311.58 217.47 311.58 210.27 321.6"
        />
      </g>
      <g id="Layer_3" data-name="Layer 3">
        <path
          className="cls-22"
          d="M619.89,266.24c0-14.39-27-26.06-60.35-26.06a131.1,131.1,0,0,0-23.37,2,1.39,1.39,0,0,1-1.49-1.33c-1.51-9.81-8.41-17.23-16.7-17.23a13,13,0,0,0-1.54.09c-.87.1-1.55-.88-1.42-2A20.22,20.22,0,0,0,512.77,209c-5.09-8.68-16.43-9.08-25.33-.91a29.19,29.19,0,0,0-6.09,8,1.2,1.2,0,0,1-2.25-.07c-.21-.46-.44-.9-.69-1.34-4-6.75-11.71-8.47-19.14-5.06-.84.39-1.75-.32-1.8-1.44a78.6,78.6,0,0,0-2.89-17.63c-7.11-25.19-24.71-40.39-39.31-33.94s-20.67,32.1-13.56,57.29v0c.41,1.41-.75,2.73-1.83,2.06a41.31,41.31,0,0,0-21.83-6.13c-21.32,0-38.61,15.65-38.67,35a1.36,1.36,0,0,1,0,.2c-3.72,27.11,24.4,45.16,27.54,47.08a1.12,1.12,0,0,0,.59.17H565.32l-.12-.12C595.87,291,619.89,279.81,619.89,266.24Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-23"
          d="M269.74,282c-.73.16-1.45.33-2.17.51a2.56,2.56,0,0,1-2.79-3.86c9.3-14.91,10.15-32.22.66-43.75-9.87-12-28-14.2-45-6.95a2.54,2.54,0,0,1-3.31-1.29,62.52,62.52,0,0,0-3.83-7.14c-13.8-22.05-38.79-31.28-55.81-20.63-14.32,9-18.44,29.14-11.18,48.44a2.55,2.55,0,0,1-3.75,3.08c-.93-.59-1.88-1.17-2.86-1.73-23.32-13.47-49-12.7-57.3,1.72-7.44,12.89,1.5,32.31,20.14,45.91h0a2.66,2.66,0,0,1-2.36.27,108.65,108.65,0,0,0-36.09-5.82C29,290.74.63,305.39.63,323.45A18.34,18.34,0,0,0,3,332.24H304.69c13.74-8.27,21.67-20.23,19.19-31.41C320.27,284.58,296,276.14,269.74,282Z"
          transform="translate(-0.63 -2.38)"
        />
        <path
          className="cls-24"
          d="M242.86,106.34c-16.14-.37-19.33,17.58-33.54,21.1-20.73,5.13-48.16-2.11-56.38,14.07C145.58,156,171.51,178.08,197.12,175c35.61-4.32,29.6,18.58,67.19,14.32,32.94-3.73,26.05-25.93,53.73-25.28,30.18.7,41.64-32.24,19-37.15-13.61-3-15.82-20.61-27-20.61S298.27,128,273.73,125.9C258.44,124.63,260.49,106.74,242.86,106.34Z"
          transform="translate(-0.63 -2.38)"
        />
      </g>
    </svg>
  );
};

TownImage.propTypes = {
  color: PropTypes.string
};

export default TownImage;
